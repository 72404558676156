
@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-toggler:focus {
        box-shadow: none;
    }
}

@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width: 768px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0px 3px 10px 0px rgb(24 59 86 / 8%);
        color: #fff !important;
    }
}

@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        color: #fff !important;
        background: #fff;
    }
}


body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

/* body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
    color: #fff !important
} */
@media(min-width: 200px)and (max-width: 1024px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        color: #fff !important;
        background: #fff
    }

    .navbar-custom .navbar-nav {
        margin-left: 0px
    }

    .navbar-custom .navbar-nav li.active {
        border-color: transparent
    }

    .navbar-custom .navbar-nav li a {
        transition: all .4s;
        margin: 6px;
        color: #183b56 !important;
        padding: 6px 0
    }

    .navbar-custom>.container {
        width: 90%
    }

    .home-title {
        font-size: 36px
    }

    .navbar-white .navbar-toggler {
        color: #183b56 !important
    }

    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: 183b56 !important
    }

    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px
    }

    .navbar-toggler:focus {
        box-shadow: none
    }

}

.navbar {
    transition: all .5s ease;
    padding: 0;
    /* background-color: transparent !important */
}

/* .navbar2 {
    transition: all .5s ease;
    padding: 0;
    background-color: transparent !important
} */

.navbar .logo .logo-light {
    display: none
}

.navbar .logo .logo-dark {
    display: inline-block
}

.navbar .navbar-nav .nav-link {
    /* color: #255F51; */
    color: #0F7A95;
    text-align: left;
    font-size: 13px;
    background-color: transparent;
    padding: 28px 25px;
    text-transform: uppercase;
    transition: all .3s ease;
    position: relative;
    font-weight: 700;

}


.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:active,
.navbar .navbar-nav .nav-link.active {
    color: #023744 !important
}

.navbar .navbar-brand {
     display: contents;
    color: hsla(159, 35%, 45%, 1);
    font-weight: 600;
    font-size: 26px
}

.navbar .navbar-brand span {
    color: #000;
    font-weight: 800
}

.navbar .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px
}

.navbar .navbar-toggler:focus {
    box-shadow: none
}

.navbar .header-item {
    color: #183b56;
    transition: all .5s ease
}

@media(max-width: 992px) {
    .navbar-nav .dropdown .dropdown-menu {
        border: none;
        box-shadow: none;
        margin-top: 0
    }
}

@media(max-width: 576px) {
    .dropdown {
        position: static
    }
}

.navbar-light .nav-item .nav-link {
    color: rgba(255, 255, 255, .5) !important
}

.navbar-light .nav-item .nav-link:hover,
.navbar-light .nav-item .nav-link:focus,
.navbar-light .nav-item .nav-link.active {
    color: #fff !important
}

.navbar-light .btn {
    background-color: #183b56 !important;
    color: #fff;
    border: none !important
}

.navbar-light .logo .logo-light {
    display: block
}

.navbar-light .logo .logo-dark {
    display: none
}

@media(max-width: 992px) {
    .navbar-light .logo .logo-light {
        display: none
    }

    .navbar-light .logo .logo-dark {
        display: block
    }
}

.nav-sticky.navbar-light .nav-item .nav-link {
    color: hsla(159, 35%, 45%, 1) !important 
}

.nav-sticky.navbar-light .nav-item .nav-link:hover,
.nav-sticky.navbar-light .nav-item .nav-link:focus,
.nav-sticky.navbar-light .nav-item .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

.nav-sticky.navbar-light .btn {
    background: linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%) !important;
    color: #fff;
    border: none !important
}

.nav-sticky.navbar-light .logo .logo-light {
    display: none !important
}

.nav-sticky.navbar-light .logo .logo-dark {
    display: block !important
}

.nav-sticky.navbar {
    background: hsla(159, 35%, 45%, 1) !important;
    margin-top: 0px;
    box-shadow: 0px 30px 10px 0px rgb(24 59 86 / 20%);
}

.nav-sticky .nav-item .nav-link {
    color: hsla(159, 35%, 45%, 1) !important
}

@media(min-width: 992px)and (max-width: 1200px) {
    .navbar .navbar-nav .nav-link {
        padding: 25px 15px
    }
}

@media(max-width: 992px) {
    .navbar {
        padding: 14px 0;
        background-color: #fff !important
    }

    .navbar .navbar-nav .nav-item .nav-link {
        padding: 8px 15px;
        color: #0F7A95 !important
    }
    .margin-top{
        margin-top: 25px;
}
}

body[data-layout-mode=dark] .navbar {
    background-color: transparent !important
}

body[data-layout-mode=dark] .nav-sticky {
    background-color: #255F51 !important
}

body[data-layout-mode=dark] .nav-sticky .nav-item .nav-link {
    color: #fff !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-toggler i,
body[data-layout-mode=dark] .navbar .navbar-toggler i {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link {
    color: #fff
}

body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link:hover,
body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link.active,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link:hover,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-brand,
body[data-layout-mode=dark] .navbar .navbar-brand {
    color: hsla(159, 35%, 45%, 1) !important;
    font-weight: 600;
    font-size: 26px
}

body[data-layout-mode=dark] .nav-sticky .navbar-brand span,
body[data-layout-mode=dark] .navbar .navbar-brand span {
    color: #fff;
    font-weight: 800
}

body[data-layout-mode=dark] .navbar-toggler {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-brand {
    color: #0076a3 !important
}

body[data-layout-mode=dark] .navbar-brand span {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-brand .logo-dark {
    display: none
}

body[data-layout-mode=dark] .navbar-brand .logo-light {
    display: inline-block
}

body[data-layout-mode=dark] .nav-sticky.navbar-light .logo .logo-light {
    display: block !important
}

body[data-layout-mode=dark] .nav-sticky.navbar-light .logo .logo-dark {
    display: none !important
}

body[data-layout-mode=dark] .navbar-light .nav-item .nav-link {
    color: rgba(255, 255, 255, .5) !important
}

body[data-layout-mode=dark] .navbar-light .nav-item .nav-link:hover,
body[data-layout-mode=dark] .navbar-light .nav-item .nav-link:focus,
body[data-layout-mode=dark] .navbar-light .nav-item .nav-link.active {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-light .btn {
    background-color: #fff !important;
    color: #183b56;
    border: none !important
}

@media(max-width: 1025px) {
    body[data-layout-mode=dark] .navbar {
        background-color: #303841 !important
    }

    body[data-layout-mode=dark] .navbar .nav-item .nav-link {
        color: #fff !important
    }
}

@media(max-width: 992px) {
    .navbar {
        padding: 14px 0;
        background-color: #fff !important
    }

    .navbar-toggler i {
        color: #183b56
    }

    .navbar .dropdown .dropdown-menu .dropdown-item {
        padding: 10px 30px
    }
}

body.modal-open .navbar {
    padding-right: 0px !important
}

.uptop {
    position: fixed;
    bottom: 50px;
    right: 50px;
}

@media(max-width: 768px) {
    .uptop {
        position: fixed;
        bottom: 50px;
        right: 25px;
    } 
}

@media(max-width: 768px) {
    .navbar-custom {
        margin-top: -9px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0px 3px 10px 0px rgba(24, 59, 86, .08);
        color: #fff !important
    }

    .navbar-toggler i {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #183b56
    }

    .bg-home {
        height: auto
    }

    .nav-sticky.navbar-custom .navbar-nav li a {
        color: #fff !important
    }

    .nav-sticky .logo {
        color: #fff !important
    }

    .clients-img {
        display: none
    }
}


#style-switcher {
    transition: all .4s;
    background: none repeat scroll 0 0 #f8f9fc;
    box-shadow: 0px 4px 12px rgba(24, 59, 86, .07);
    left: -189px;
    position: fixed;
    top: 35.5%;
    width: 189px;
    z-index: 999;
    padding: 10px 5px
}

#style-switcher .settings {
    background: #343a40;
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: -45px;
    top: 0;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center
}


.btn {
    border: 1px solid #0F7A95 ;
    /* border: 1px solid #255f51; */
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .5px;
    margin-right: 27px;
    overflow: hidden;
    padding: 12px 30px;
    position: relative;
    transition: all .5s;
}


.bg-gradiant {
    /* background: linear-gradient(90deg,#4b9b7f,#0a3331); */
    background: linear-gradient(90deg, #0F7A95 0%, #023744 100%);
    border: none!important;
    color: #fff!important;
    text-transform: uppercase;
}

.bg-gradiant:hover {
    background: linear-gradient(90deg, #023744 0%, #0F7A95 100%);
}


.navbar .navbar-toggler {
    border: none;
    font-size: 24px;
    padding: 0;
}


[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

@media (min-width: 200px) and (max-width: 1024px)
{.navbar-toggler {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 0;
}}


.mdi-menu:before {
    content: "\F035C";
}

.mdi-set, .mdi:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font: normal normal normal 24px/1 Material Design Icons;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
}


.close {
    height: 32px;
    opacity: .4;
    position: absolute;
    right: 15px;
    top: 41px;
    width: 32px;
    background-image: url(../../../assets/svg/close-small.svg);
}


.open {
    height: 32px;
    opacity: 1.4;
    position: absolute;
    right: 16px;
    top: 32px;
    width: 32px;
    background-image: url(../../../assets/svg/open2.svg);
}

.navbar-custom .navbar-nav li a:active, .navbar-custom .navbar-nav li a:focus, .navbar-custom .navbar-nav li a:hover, .navbar-custom .navbar-nav li.active a {
    color: #023744 !important;
}


/* body{
    font-family: Roboto,sans-serif!important;
} */

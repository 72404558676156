
.header {
    position: relative;
    height: 100vh;
    min-height: 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* background-image: url(../../assets/images/header.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.header .overlay1 {
    margin-top: -71px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: -webkit-linear-gradient(bottom, #fff 0%,#0F7A95  99%, rgba(105, 90, 166, 0.5)  100%);
    /* background-image: -webkit-linear-gradient(bottom, #fff 0%,hsla(159, 35%, 45%, 1) 99%, rgba(105, 90, 166, 0.5)  100%); */
    /* background-image: linear-gradient(to top, #fff 0%, rgba(105, 90, 166, 0.35) 99%, rgba(105, 90, 166, 0.5) 100%); */
}


.header .header-content {
    margin-top: 20px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    /* max-width: 550px; */
    /* max-width: 314px; */
}

.header-title {
    font-size: 4.4rem;
    font-weight: bold;
    opacity: .8;
    color: #212529;
}

.header .header-content .header-subtitle {
    font-size: calc(10px + (22 - 10) * ((70vw - 300px) / (1300 - 300)));
    /* letter-spacing: calc(1px + (7 - 1) * ((100vw - 300px) / (1300 - 300))); */
    opacity: .8;
    margin-top: -10px;
    margin-bottom: 20px;
}

.header .header-content .header-title .up {
    font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1300 - 300)));
    font-weight: 500;
    color: #444;
    margin-bottom: 5px;
    position: relative;
    display: block;
}

.header .header-content .header-title .down {
    font-size: calc(15px + (75 - 30) * ((100vw - 300px) / (1300 - 300)));
    margin-bottom: 0;
    position: relative;
    display: block;
}

.header .header-content .btn {
    /* border: 1px solid #255f51 !important; */
    position: relative;
    margin-top: 20px;
    /* margin-left: -370px; */
    /* border-radius: 20px; */
    padding: 8px 15px;
}

.btn:hover {
  background: linear-gradient(90deg, #023744 0%,#0F7A95 100%)
}

@media only screen and (max-width:412px) {
  .header .header-content .btn {
    margin-left: 0px;
  }
}

@media only screen and (max-width:412px) {
  .header {
    height: 50dvh;
  }
}

.h1{
    font-family: 'Baloo Paaji', cursive;
}

p {
    font-size: calc(13px + (15 - 13) * ((100vw - 300px) / (1300 - 300)));
    font-weight: 100;
    opacity: 1;
    color: #111;
    letter-spacing: .6px;
}

.fontt-color{
/* color: #255F51; */
color: #0F7A95 ;
}

.btn-outline-primary {
    color: #0F7A95 ;
    border-color: #0F7A95;
    /* color: #255F51;
    border-color: #255F51; */
}

.btn-rounded {
    border-radius: 100px;
}

.btn:hover {
    color:white;
    background-color: #0F7A95;
    border-color: #0F7A95;
}

.opacity{
    opacity: .6;
}

.service-card {
    position: relative;
    min-height: 260px;
    border: 1px solid #dee2e6;
    overflow: hidden;
    border-radius: 3px;
  }
  
  .service-card .body {
    padding: 10px;
  }
  
  .service-card .body img.icon {
    margin-top: 40px;
    width: 55px;
    margin-bottom: 25px;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .service-card .body img.icon {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .service-card .body .subtitle {
    position: absolute;
    bottom: -100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .service-card .body .subtitle {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .service-card:hover .body img.icon {
    margin-top: 5px;
    width: 45px;
    margin-bottom: 15px;
  }
  
  .service-card:hover .body .subtitle {
    bottom: 7px;
    opacity: 1;
    visibility: visible;
  }
  
  .title {
    font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1300 - 300)));
}

/*/ portfolio wrapper */
.portfolio-card {
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .portfolio-card .portfolio-card-img {
    width: 100%;
  }
  
  .portfolio-card .portfolio-card-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-image: -webkit-linear-gradient(bottom, #e1dfed 0%, rgba(225, 223, 237, 0.7) 99%, rgba(225, 223, 237, 0.8) 100%);
    background-image: linear-gradient(to top, #e1dfed 0%, rgba(225, 223, 237, 0.7) 99%, rgba(225, 223, 237, 0.8) 100%);
    -webkit-transition: all, 0.3s;
    transition: all, 0.3s;
    width: 0;
    height: 0;
    border-radius: 50%;
    overflow: hidden;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .portfolio-card .portfolio-card-overlay {
      -webkit-transition: none;
      transition: none;
    }
  }
  
  .portfolio-card .portfolio-card-overlay .portfolio-card-caption {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .portfolio-card:hover .portfolio-card-overlay {
    opacity: 1;
    visibility: visible;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  
  @media (max-width: 767.98px) {
    .portfolio-card {
      margin-bottom: 30px;
    }
    .service-card {
      margin-bottom: 30px;
    }
  }


  /* hire me section */
  
  .section-sm {
    padding: 40px 0 !important;
}

.bg-primary {
  background-color:  #255f51 !important;
}

/* testimonials */
.carousel-caption {
  position: absolute;
  right: 14%;
  bottom: 0.25rem;
  left: 11%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@media only screen and (max-width:412px) {
 .sktln{
  width: 350px !important;
 }
}

@media only screen and (max-width:412px) {
 .sktnlon2testii{
  width: 171px !important;
  margin-right: 194px !important;
 }
}

.carousel-control-prev-icon {
  /* background: #255F51; */
  background-image: url("../../assets/images/download.png")
}
.carousel-control-next-icon {
  /* background: #255F51; */
  background-image: url("../../assets/images/download\ 1.png")
}

.carousel-control-prev-icon:hover {
  transform: scale(1.5);
}
.carousel-control-next-icon:hover {
  transform: scale(1.5);
}


.carousel-control-next-icon, .carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.carousel-control-next, .carousel-control-prev {
  align-items: center;
  background: 0 0;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: .5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity .15s ease;
  width: 9%;
  z-index: 1;
}

.fontamy{
  font-family: Roboto,sans-serif;
}
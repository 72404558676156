
/* contact */

.ftco-section {
  padding: 7em 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 1200px) {
  .container1 {
    max-width: 1140px;
  }
}

@media (min-width: 992px) {
  .container1 {
    max-width: 960px;
  }
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.row07 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.contact-wrap {
  background: #fff;
}

.form-group {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .p-md-5 {
    padding: 3rem !important;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.form-control {
  height: 49px;
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 15px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .info-wrap {
    /* margin-top: -20px; */
    /* margin-bottom: -20px; */
    /* border-radius: 5px; */
  }
}

.bg-primary {
  /* background:linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%); */
  background:linear-gradient(90deg, #0F7A95 0%, #023744 100%);
}

@media (min-width: 992px) {
  .p-lg-5 {
    padding: 3rem !important;
  }
}


.info-wrap h3 {
  color: white !important;
}


@media (min-width: 768px) {

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}


.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}

.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}


.iconn {
  width: 90px;
  /* width: 53px; */
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}


.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.info-wrap .dbox p span {
  font-weight: 600;
  color: #fff;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* line-height: 1.8; */
}


@media (min-width: 992px) {
  .info-wrap {
    margin-top: -20px;
    margin-bottom: -20px;
    border-radius: 3px;
  }
}

.text-07 {
  margin-top: 10px;
  margin-left: 10px;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}


.submitting {
  float: left;
  width: 100%;
  padding: 10px 0;
  display: none;
  font-size: 16px;
  font-weight: bold;
}


/* .w-100 {
  width: 100% !important;
} */

.error {
  color:#db2269;
  font-size: 0.80em;
  display: relative;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.fw-bold {
  font-weight: 700!important;
}

.fontt-color{
  color: #0F7A95;
  }

.green-boder{
  /* border-radius: 45px; */
  border-radius: 10px 100px / 120px;
}
.rounded-corners
{
  border-radius: 10px 100px / 120px;
}

.border-info{
  border-color: #0F7A95 !important;
}



/*** Service ***/
.service-item .service-text {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .06);
    transition: .5s;
    z-index: 2;
}

@media only screen and (max-width: 1000px) {
    .service-item .service-text {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        padding: 10px !important;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0 0 45px rgba(0, 0, 0, .06);
        transition: .5s;
        z-index: 2;
    }
  }


  @media only screen and (max-width: 700px) {
    .wow{
        margin-bottom: 20px;
    }
  }
.service-item:hover .service-text {
    top: -1.5rem;
}

.service-item .service-text h5 {
    transition: .5s;
}

.service-item:hover .service-text h5 {
    color: var(--primary);
}

.service-item .service-btn {
    position: absolute;
    width: 80%;
    height: 3rem;
    left: 10%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
    transition: .5s;
    z-index: 1;
}

.service-item:hover .service-btn {
    bottom: -1.5rem;
}



/*** Testimonial ***/
   
.carousel .carousel-item {
	color: #999;
	overflow: hidden;
	min-height: 120px;
	font-size: 13px;
}
.carousel .media img {
	width: 80px;
	height: 80px;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 0 15px 0 60px ;
	position: relative;
}

.carousel .overview b {
	text-transform: uppercase;
	/* color: #255F51; */
	color: #0F7A95;
  margin-right: 430px;
}

.text-testi{
  /* color: #255F51!important; */
  color: #0F7A95!important;
  text-align: initial;
}
.carousel .carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin: 1px 3px;
	box-sizing: border-box;
}
.carousel-indicators li {	
	background: #e2e2e2;
	border: 4px solid #fff;
}
.carousel-indicators li.active {
	color: #fff;
	background: #255F51;    
	border: 5px double;    
}




@media only screen and (max-width: 767px) {

    .carousel .media img {
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 50%;
        display: none;
    }
    .carousel .testimonial {
        padding: 0 15px 0 60px ;
        position: relative;
        display: none;
    }
    
    .carousel .carousel-item {
      color: #999;
      overflow: hidden;
      min-height: 120px;
      font-size: 13px;
      display: none;
    }


}

/* new-service */

@media only screen and (max-width: 2000px) {

  .displ{
    display: none;
  }
  .testimonia2 {
    border-right: 4px solid #2a3d7d;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 30px 30px 130px;
    margin: 0 15px 30px 15px;
    overflow: hidden;
    position: relative;
    display: none;
    
  }
  .testimonial2:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: -17px;
    border-top: 25px solid #255F51;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(45deg);
    display: none;
  }
  .testimonial2:after {
    content: "";
    position: absolute;
    top: -4px;
    left: -17px;
    border-top: 25px solid #255F51;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(135deg);

  }
  .testimonial2 .pic {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 60px;
    left: 20px;
    display: none;
  }
  .testimonial2 .pic img {
    width: 100%;
    height: auto;
    display: none;
  }
  .testimonial2 .description2 {
    font-size: 15px;
    letter-spacing: 1px;
    color: #6f6f6f;
    line-height: 25px;
    margin-bottom: 15px;
    display: none;
  }
  .testimonial2 .title {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #255F51;
    margin: 0;
    display: none;
  }
  .testimonial2 .post {
    display: inline-block;
    font-size: 17px;
    color: #255F51;
    font-style: italic;
    display: none;
  }
  .owl-theme .owl-controls .owl-page span {
    border: 2px solid #2a3d7d;
    background: #fff !important;
    border-radius: 0 !important;
    opacity: 1;
  }
  .owl-theme .owl-controls .owl-page.active span,
  .owl-theme .owl-controls .owl-page:hover span {
    /* background: #255F51 !important; */
    background: #0F7A95 !important;
    /* border-color: #255F51; */
    border-color: #0F7A95;
  }


.dynamic-star-rating {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-content: stretch;
  justify-content: start;
  /* display: none; */
}


}
  @media only screen and (max-width: 767px) {
    .displ{
      display: block;
    }
    .testimonial2 {
      padding: 20px;
      text-align: center;
      display: block;
    }
    .testimonial2 .pic {
      display: block;
      position: static;
      margin: 0 auto 15px;
    }

      .testimonial2:before {
        content: "";
        position: absolute;
        bottom: -4px;
        left: -17px;
        /* border-top: 25px solid #255F51; */
        border-top: 25px solid #0F7A95;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        transform: rotate(45deg);
        display: block;
      }
      .testimonial2:after {
        content: "";
        position: absolute;
        top: -4px;
        left: -17px;
        /* border-top: 25px solid #255F51; */
        border-top: 25px solid #0F7A95;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        transform: rotate(135deg);
    
      }

      .testimonial2 .pic img {
        width: 100%;
        height: auto;
        display: block;
      }
      .testimonial2 .description2 {
        font-size: 15px;
        letter-spacing: 1px;
        color: #6f6f6f;
        line-height: 25px;
        margin-bottom: 15px;
        display: block;
      }
      .testimonial2 .title {
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        /* color: #255F51; */
        color: #0F7A95;
        margin: 0;
        display: block;
      }
      .testimonial2 .post {
        display: inline-block;
        font-size: 17px;
        color: #0F7A95;
        /* color: #255F51; */
        font-style: italic;
        display: block;
      }

      .dynamic-star-rating {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row-reverse;
        align-content: stretch;
        justify-content: center;
      }

  }


  .aboutimg {
    border: none;
    color: #000;
    max-width: 20%;
    vertical-align: middle;
}

.text{
    color: #0F7A95;
}
*{
    box-sizing:border-box;
  }
  .wrapper-1{
    width:100%;
    display: flex;
  flex-direction: column;
  
  }
  .wrapper-2{
    font-size: 150%;
    padding :30px;
    text-align:center;
  }
  .wrapperh1{
    font-family: 'Kaushan Script', cursive;
    font-size:4em;
    letter-spacing:3px;
    color:#0F7A95;
    margin:0;
    margin-bottom:20px;
  }
  .wrapper-2 p{
    margin:0;
    font-size:1.3em;
    color:#aaa;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing:1px;
  }
  .go-home{
    color:#fff;
    background: #0F7A95;
    border:none;
    padding:10px 50px;
    margin:30px 0;
    border-radius:30px;
    text-transform:capitalize;
    box-shadow: 0 10px 10px 1px #0F7A95;
  }
  
  
  @media (min-width:360px){
    .wrapper h1{
      font-size:4.5em;
    }
    .go-home{
      margin-bottom:20px;
    }
  }
  


  @media (min-width:600px){
    .content{
    max-width:10000px;
    margin:0 auto;
  }


  
  .wrapper-1{
    height: initial;
    max-width:620px;
    margin:0 auto;
    margin-top:50px;
    box-shadow: 4px 8px 40px 8px rgba(88, 146, 255, 0.2);
  }
    
  }

.thankyoumt {
    margin-top: 10%;
    margin-bottom: 10%;
}
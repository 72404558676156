
/*** Testimonial ***/
   
.carousel .carousel-item {
	color: #999;
	overflow: hidden;
	min-height: 120px;
	font-size: 13px;
}
.carousel .media img {
	width: 80px;
	height: 80px;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 0 15px 0 60px ;
	position: relative;
}

.carousel .overview b {
	text-transform: uppercase;
	color: #0F7A95;
  margin-right: 430px;
}

.text-testi{
  color: #0F7A95!important;
  text-align: initial;
}
.carousel .carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin: 1px 3px;
	box-sizing: border-box;
}
.carousel-indicators li {	
	background: #e2e2e2;
	border: 4px solid #fff;
}
.carousel-indicators li.active {
	color: #fff;
	background: #0F7A95;    
	border: 5px double;    
}




@media only screen and (max-width: 360px) {
 
/* 
    .carousel .media img {
        width: 80px;
        height: 80px;
        display: block;
        border-radius: 50%;
        display: block;
    }
    .carousel .testimonial {
        padding: 0 15px 0 60px ;
        position: relative;
        display: block;
    }
    
    .carousel .carousel-item {
      color: #999;
      overflow: hidden;
      min-height: 120px;
      font-size: 13px;
      display: block;
    }
 */

}


  
  .fontt-color{
    /* color: #255F51; */
    color: #0F7A95;
    }

  /* extraa */

  .section {
    padding-top: 30px;
    /* padding-bottom: 120px; */
     /* background-color: #F8F9FC; */
    position: relative !important;
} 

/* 
@media (min-width: 1200px)
{.container {
    max-width: 1140px !important;
}}

@media (min-width: 1200px)
{.container {
    max-width: 1140px !important;
}} */


.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
}

.body {
    font-family: "Roboto", sans-serif;
    overflow-x: hidden !important;
    /* color: #255F51; */
    color: #0F7A95;
}

.row>* {
    position: relative;
}
/* 
@media (min-width: 992px)
{.col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
}}

@media (min-width: 992px)
{.col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
}}

.testimonial .testi-slider {
    margin: 0 20px;
}

.carousel .carousel-indicators {
    bottom: -40px;
}

@media (min-width: 1200px)
{.container {
max-width: 1140px !important;
}}

@media only screen and (max-width: 2000px)
{.displ {
    display: block;
}}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}  */

/* .carousel-control-prev-icon {
  background: #255f51;
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E);
}

.carousel-control-next-icon {
  background: #255f51;
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
} */

.carousel .testimonial {
  margin-left: -36px;
  padding: 0 15px 0 60px;
  position: relative;
}



@media (min-width: 820px)
{.text-wrap {
    white-space: normal!important;
    width: 34rem;
    width: 30rem;
}}

.text-wrap {
  white-space: normal!important;
  width: 34rem;
    line-height: 25px;
}

 @media only screen and (max-width: 600px) {
  .text-wrap {
    white-space: normal!important;
    width: 13rem;
    line-height: 17px;
  }
} 
 @media only screen and (max-width: 600px) {
  .carousel .media img {
    height: 58px;
}
} 
 @media only screen and (max-width: 600px) {
  .carousel .testimonial {
    margin-left: -45px;
}
} 

.text-primary {
  --bs-text-opacity: 1;
  color: #0F7A95!important;
}


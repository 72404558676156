h1,
h2,
h3,
h4,
h5,
h6 
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}
.footer {
/* background: linear-gradient(90deg, #0F7A95 0%, #023744 100%); */
background:linear-gradient(90deg, #023744 0%,#0F7A95 100%);

padding-top: 40px;
padding-bottom: 1px;
}
/*END FOOTER SOCIAL DESIGN*/
/* .single_footer{} */
@media only screen and (max-width:768px) { 
.single_footer{margin-bottom:30px;}
}
.single_footer h4 {
color: #fff;
margin-top: 0;
margin-bottom: 25px;
font-weight: 700;
text-transform: uppercase;
font-size: 20px;
}
.single_footer h4::after {
content: "";
display: block;
height: 2px;
width: 40px;
background: #fff;
margin-top: 20px;
}
.single_footer p{color:#fff;}
.single_footer ul {
margin: 0;
padding: 0;
list-style: none;
}
/* .single_footer ul li{} */
.single_footer ul li a {
color: #fff;
-webkit-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
line-height: 36px;
font-size: 15px;
text-transform: capitalize;
}
.single_footer ul li a:hover { color: #ff3666; }

.single_footer_address ul li{color:#fff;}
.single_footer_address ul li span {
font-weight: 400;
color: #fff;
line-height: 28px;
}
.contact_social ul {
list-style: outside none none;
margin: 0;
padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
display: block;
position: relative;
margin-top: 15px;
width: 100%;
}
.subscribe__input {
background-color: #fff;
border: medium none;
border-radius: 5px;
color: #333;
display: block;
font-size: 15px;
font-weight: 500;
height: 60px;
letter-spacing: 0.4px;
margin: 0;
padding: 0 150px 0 20px;
/* text-align: center; */
text-transform: capitalize;
width: 100%;
}

.subscribe__input:focus{
outline: none!important;
}
@media only screen and (max-width:768px) { 
.subscribe__input{padding: 0 50px 0 20px;}
}

.subscribe__btn {
background-color: transparent;
border-radius: 0 25px 25px 0;
color: linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
cursor: pointer;
display: block;
font-size: 20px;
height: 60px;
position: absolute;
right: 0;
top: 0;
width: 60px;
}
.subscribe__btn i{transition: all 0.3s ease 0s;}
@media only screen and (max-width:768px) { 
.subscribe__btn{right:0px;}
}

.subscribe__btn:hover i{
color:#183b56;
}
button {
padding: 0;
border: none;
background-color: transparent;
-webkit-border-radius: 0;
border-radius: 0;
}

.social_profile ul li a:hover{
color:#fff;
border:0px;



}
/*END SOCIAL PROFILE CSS*/
.copyright {
margin-top: 20px;
padding-top: 20px;
color:#fff;
font-size: 15px;
border-top: 1px solid rgba(255,255,255,0.4);
text-align: center;
}
.copyright a{color:white;
transition: all 0.2s ease 0s;
}
.copyright a:hover{color:hsla(159, 35%, 45%, 1);
padding-left: 4px;
}


.single_footer ul li a:hover{
color: white; 
padding-left: 4px;

}


/* footer new */
.logonew {
margin-top:70px;
}
.logonew body {
margin: 0;
padding:0;
background: #262626;
}

.logonew ul {
display: flex;
position: absolute;
top: 86%;
left: 50%;
transform: translate(-50%, -50%);
}


@media(min-width: 820px) {

.logonew ul {
display: flex;
position: absolute;
top: 85%;
left: 50%;
transform: translate(-50%, -50%);
}
}


.logonew ul li {
list-style: none;
}

.logonew ul li a {
width: 50px;
height: 50px;
background-color: #fff;
text-align: center;
line-height: 41px;
font-size: 20px;
margin: 0 5px;
display: block;
border-radius: 50%;
position: relative;
overflow: hidden;
border: 3px solid #fff;
z-index: 1;
}

.logonew ul li a .icon {
position: relative;
color: #262626;
transition: .5s;
z-index: 3;
}

.logonew ul li a:hover .icon {
color: #fff;
transform: rotateY(360deg);
}

.logonew ul li a:before {
content: "";
position: absolute;
top: 100%;
left: 0;
width: 100%;
height: 100%;
background: #f00;
transition: .5s;
z-index: 2;
}

.logonew ul li a:hover:before {
top: 0;
}

.logonew ul li:nth-child(1) a:before{
background: #3b5999;
}

.logonew ul li:nth-child(2) a:before{
background: #d62976;
}

.logonew ul li:nth-child(3) a:before {
background: #0077b5;
}

.logonew ul li:nth-child(4) a:before {
background: #dd4b39;
}


.send_msg{
background:linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
}

.footer-icon {
color: white;
width: 50px;
 height: 50px;
 border-radius: 50%;
 background: rgba(255, 255, 255, 0.1);
}


.footesize {
font-size: 16px !important;
color: #fff;
margin-top: 0;
margin-bottom: 25px;
font-weight: 700;
/* text-transform: uppercase; */
font-size: 20px;
/* text-align: center; */
}


.footerleft{
display: flex;
position: absolute;
left: 35%;
top: 170px;
}


@media only screen and (max-width: 600px) {
.footerleft{
 display: flex;
 position: absolute;
 left: 50%;
 top: 150px;
}
}